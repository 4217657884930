import memberPath from '../config/member-path'
export const useMemberNavigator = () => {
  const _navigate = (path: string, options?: any) => {
    const navigateOptions = {
      path,
      ...options
    }

    navigateTo(navigateOptions)
  }

  /** 회원가입 페이지 이동 */
  const navigateToJoin = (options?: any) => {
    const path = memberPath.PATH_JOIN_PAGE
    _navigate(path, options)
  }

  /** 로그인 페이지 이동 */
  const navigateToLogin = (options?: any) => {
    const path = memberPath.PATH_LOGIN_PAGE
    _navigate(path, options)
  }

  /** 이메일 로그인 페이지 이동 */
  const navigateToEmailLogin = (options?: any) => {
    const path = memberPath.PATH_LOGIN_EMAIL
    _navigate(path, options)
  }

  /** 로그인 성공후 이동 */
  const navigateToSuccessLogin = (options?: any) => {
    const path = memberPath.PATH_SUCCESS_LOGIN_PATH
    _navigate(path, options)
  }

  return {
    navigateToJoin,
    navigateToLogin,
    navigateToEmailLogin,
    navigateToSuccessLogin
  }
}
